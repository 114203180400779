<template>
  <div v-if="endTime !== undefined" class="base-timer">
    <div v-if="isDaily" :class="['base-timer__daily', dailyClasses]">
      {{ dailyTime }}
    </div>
    <div v-else-if="!isTimerEnded || dailyEventTimer" :class="['base-timer__event', eventClasses]">
      <div v-for="item in eventTime" :key="item.subtitle" class="base-timer__event-item">
        <div class="base-timer__event-item__text">
          <span class="base-timer__event-item__text--title">{{ item.title }}</span>
          <span class="base-timer__event-item__text--subtitle">{{ item.subtitle }}</span>
        </div>
      </div>
    </div>
    <div v-else :class="['base-timer__end base-timer__event', eventClasses]">
      <span class="base-timer__end-title">{{ $t('mainPage.eventTimer.end') }}</span>
      <span class="base-timer__end-subtitle">{{ tillTimeDate }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IBaseTimerProps } from './BaseTimer.types';
import { EDeclamations, getPaddedTime, getParsedTime } from './BaseTimer.data';
import { TIMES } from '~/constants/times.constants';

const {
  $i18n: { t },
} = useNuxtApp();

const props = defineProps<IBaseTimerProps>();
const { tillTime, type, noSpaces } = toRefs(props);

const interval = ref<ReturnType<typeof setInterval>>();
const endTime = ref<number>();

const dailyClasses = computed(() => ({
  'base-timer__daily--ended': isTimerEnded.value,
  'base-timer__daily--transparent': type.value === 'daily-transparent',
  'base-timer__daily--red': type.value === 'daily-red',
}));

const eventClasses = computed(() => ({
  'base-timer__event--black': type.value === 'event-black' || 'event-black-daily',
  'base-timer__event--white': type.value === 'event-white',
}));

const isDaily = computed(
  () => type.value === 'daily' || type.value === 'daily-transparent' || type.value === 'daily-red',
);

const dailyEventTimer = computed(() => type.value === 'event-black-daily');

const isTimerEnded = computed(() => endTime.value === 0);

const remainingTime = computed(() => {
  if (endTime.value === undefined) return null;
  return getParsedTime(endTime.value);
});

const dailyTime = computed(() => {
  if (!remainingTime.value) return;
  const delimiter = noSpaces.value ? '' : ' ';

  return `${remainingTime.value.hours}${delimiter}:${delimiter}${remainingTime.value.minutes}${delimiter}:${delimiter}${remainingTime.value.seconds}`;
});

const eventTime = computed(() => {
  if (!remainingTime.value) return;

  const result = [];

  let enumKey: keyof typeof EDeclamations;
  for (enumKey in EDeclamations) {
    const key = EDeclamations[enumKey];
    const value = remainingTime.value[key];

    result.push({
      subtitle: t(`timer.${key}`, +value),
      title: value,
    });
  }

  return result;
});

const tillTimeDate = computed(() => {
  if (!tillTime.value) return;

  const date = new Date(tillTime.value);
  return `${getPaddedTime(date.getDate())}.${getPaddedTime(date.getMonth() + 1)}.${date.getFullYear()}`;
});

onMounted(() => {
  watch(
    tillTime,
    (newTime) => {
      resetTimer();

      // Проверяем передаем мы секунды или миллисекунды
      if (newTime > TIMES.DAY) {
        endTime.value = Math.max(0, Math.trunc((newTime - Date.now()) / 1000));
      } else {
        endTime.value = newTime;
      }

      if (endTime.value <= 0) return;

      interval.value = setInterval(() => {
        if (!endTime.value || endTime.value <= 0) return resetTimer();
        endTime.value--;
      }, 1000);
    },
    { immediate: true },
  );
});

onUnmounted(() => resetTimer());

const resetTimer = () => {
  if (!interval.value) return;
  clearInterval(interval.value);
  interval.value = undefined;
};
</script>

<style scoped lang="scss" src="./BaseTimer.scss" />
