export enum EDeclamations {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
}

export const getParsedTime = (time: number) => {
  const days = getPaddedTime(Math.trunc(time / 86400));
  const hours = getPaddedTime(Math.trunc((time % 86400) / 3600));
  const minutes = getPaddedTime(Math.trunc((time % 3600) / 60));
  const seconds = getPaddedTime(Math.trunc(time % 60));

  return { days, hours, minutes, seconds };
};

export const getPaddedTime = (time: number) => `${time}`.padStart(2, '0');
